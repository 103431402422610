










import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  name: 'LoopingBG',
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Img: Utils.loadComponent('proxy/Image'),
  },
})
export default class LoopingBG extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) protected logo!: boolean

  protected alienList: Array<[string, string]> = []
  protected alienAdder: NodeJS.Timer | null = null

  protected mounted() {
    this.alienAdder = setInterval(() => {
      const rnd = (max: number, min = 0) => {
        return Math.floor(Math.random() * (max - min + 1) + min) + 'px'
      }
      const el = this.$refs['alien-bg'] as Element
      this.clickers(null, [
        rnd(el.clientWidth - 108),
        rnd(el.clientHeight - 108),
      ])
    }, 5000)
  }

  protected beforeDestroy() {
    if (this.alienAdder) {
      clearInterval(this.alienAdder)
    }
  }

  private clickers(event: PointerEvent | null, percentCoords?: [string, string]) {
    if (event) {
      this.alienList.push([event.offsetX - 54 + 'px', event.offsetY - 54 + 'px'])
    } else if (percentCoords) {
      this.alienList.push(percentCoords)
    }
    if (this.alienList.length) {
      setTimeout(() => {
        this.alienList.shift()
      }, 1000)
    }
  }
}
